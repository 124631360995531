import { Button, Form, InputNumber, Modal, Space, notification } from 'antd'
import { MaskedInput } from 'antd-mask-input'
import axios from 'axios'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import getDefaultHeader from '../../../../../heplers/getDefaultHeader'
import getDefaultObject from '../../../../../heplers/getDefaultObject'
import makeNumberPretty from '../../../../../heplers/makeNumberPretty'
import { changeGlobalError } from '../../../../../redux/ducks/globalError'

const BASE_API_PATH = import.meta.env.VITE_REACT_APP_API_URL

const checkout = new cp.Checkout({
	publicId: 'pk_c79d46b7f65bb66874c5a5010ffef'
})

const checkValidMaxLenght = (value, max, min) => {
	const regExp = /\*|_|#|&|\$/g
	const v = value.replace(regExp, '')
	if (v.length < max) {
		return false
	}

	if (v.length < min) {
		return false
	}
	return true
}

const getFine = ({ vals, min }) => {
	let a = +vals.Номинал + +vals.Комиссия + +vals.Пени + +vals.ПениКомиссия // + (+vals.Проценты)
	let b = +a + +vals.Проценты
	if (min) {
		b = b - +min
	}
	return `${makeNumberPretty(b)}`
}

const CloudModal = ({ visible, callback, initVals, record }) => {
	const globalError = useSelector(state => state.globalError)
	const [form] = Form.useForm()
	const [err, setErr] = useState('')
	const [stage, setStage] = React.useState(1)
	const [cryp, setCryp] = React.useState('')
	const dispatch = useDispatch()
	const userData = useSelector(state => state.user.data)
	const [r, setR] = useState(false)

	const check = date => {
		const fieldValues = {
			cvv: date.cardCVV,
			cardNumber: date.cardName,
			expDateMonth: moment(date.cardDate).format('MM/YY').split('/')[0],
			expDateYear: moment(date.cardDate).format('MM/YY').split('/')[1]
		}

		checkout
			.createPaymentCryptogram(fieldValues)
			.then(cryptogram => {
				setCryp(cryptogram)
				setErr('')
				setStage(2)
			})
			.catch(errors => {
				if (errors.cardNumber === 'CardNumber_Invalid') {
					setErr('Неверный номер карты')
				}
			})
	}
	const onFinish = async values => {
		console.log(values)
		const obj = {
			...getDefaultObject(),
			CardCryptogramPacket: cryp,
			Сумма: values.Sum,
			UIDОснование: initVals.UIDДоговора,
			ВидОперации: initVals.ТипОперации,
			Payer: {
				Phone: `+${userData.ИнформацияОКлиенте.ФизЛицо.МобильныйТелефон}`,
				FirstName:
					userData?.ИнформацияОКлиенте?.ФизЛицо?.Наименование.split(' ')[1],
				LastName:
					userData?.ИнформацияОКлиенте?.ФизЛицо?.Наименование.split(' ')[0],
				MiddleName:
					userData?.ИнформацияОКлиенте?.ФизЛицо?.Наименование.split(' ')[2],
				Birth: userData?.ИнформацияОКлиенте?.ФизЛицо?.ДатаРождения
			}
		}
		const hueta = async (url, data) => {
			await axios.post(url, data)
		}

		await axios
			.post(
				`${BASE_API_PATH}/payment/create/cloudpayments`,
				obj,
				getDefaultHeader()
			)
			.then(res => {
				if (!!!res.data.error) {
					const form = new FormData()
					form.append('PaReq', res.data.url.Model.paReq)
					form.append('MD', res.data.url.Model.transactionId)
					form.append('TermUrl', window.location.href)

					hueta(res.data.url.Model.acsUrl, form)
				} else {
					dispatch(changeGlobalError(res.data.error_message))
				}
			})
			.catch(err => {
				dispatch(changeGlobalError(err))
			})
	}

	const onFinishFailed = errorInfo => {
		console.log('Failed:', errorInfo)
	}

	useEffect(() => {
		if (globalError) {
			notification.error({
				message: 'Ошибка',
				description: globalError
			})
		}
		dispatch(changeGlobalError(null))
	}, globalError)

	return (
		<Modal open={visible} title={'Оплатить'} footer={null} onCancel={callback}>
			<Form
				form={form}
				style={{ width: '100%' }}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
			>
				<Form.Item
					name='cardName'
					label='Номер карты'
					hidden={stage !== 1}
					rules={[
						{
							required: stage === 1,
							message: 'Обязательное поле для заполнения'
						},
						({ getFieldValue }) => ({
							validator(_, value) {
								if (checkValidMaxLenght(value, 19, 15)) {
									return Promise.resolve()
								}
								return Promise.reject(
									new Error('Минимальное значение 15, масимальное 19')
								)
							}
						})
					]}
				>
					<MaskedInput name='cardName' mask='0000 0000 0000 0000' />
				</Form.Item>
				<Space style={{ width: '100%' }}>
					<Form.Item
						name='cardDate'
						label='Дата'
						hidden={stage !== 1}
						rules={[
							{
								required: stage === 1,
								message: 'Обязательное поле для заполнения'
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (checkValidMaxLenght(value, 5, 5)) {
										return Promise.resolve()
									}
									return Promise.reject(
										new Error('Минимальное значение 4, масимальное 4')
									)
								}
							})
						]}
					>
						<MaskedInput name='cardDate' mask='00/00' />
					</Form.Item>

					<Form.Item
						name='cardCVV'
						label='CVV'
						hidden={stage !== 1}
						rules={[
							{
								required: stage === 1,
								message: 'Обязательное поле для заполнения'
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (checkValidMaxLenght(value, 3, 3)) {
										return Promise.resolve()
									}
									return Promise.reject(
										new Error('Минимальное значение 3, масимальное 3')
									)
								}
							})
						]}
					>
						<MaskedInput
							name='cardCVV'
							mask='000'
							maxLength={4}
							minLength={4}
						/>
					</Form.Item>

					<div
						style={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}
					>
						{stage === 2 && (
							<>
								<span>
									Полный платеж: {getFine({ vals: record.пПлатеж })} руб.
								</span>
								<span>
									Платеж с учетом резерва:{' '}
									{getFine({ vals: record.тПлатеж, min: record.Резерв })} руб.
								</span>
							</>
						)}

						<Form.Item
							name='Sum'
							label='Введите сумму'
							hidden={stage === 1}
							rules={[
								{
									required: stage === 2,
									message: 'Обязательное поле для заполнения'
								}
							]}
							style={{ width: '100%' }}
						>
							<InputNumber
								name='Sum'
								placeholder='Введите сумму'
								style={{ width: '100%' }}
							/>
						</Form.Item>
					</div>
				</Space>
				{err && (
					<div
						style={{
							padding: '1rem',
							margin: '1rem 0',
							backgroundColor: '#e3342f',
							color: 'white',
							borderRadius: '.5rem'
						}}
					>
						<span>{err}</span>
					</div>
				)}
				<div style={{ display: 'flex', justifyContent: 'space-between' }}>
					{stage !== 1 ? (
						<Button onClick={() => setStage(1)}>Назад</Button>
					) : (
						<Button
							onClick={() =>
								form
									.validateFields()
									.then(v => check(v))
									.catch(e => console.log(e))
							}
						>
							Далее
						</Button>
					)}

					{stage === 2 && (
						<Button type='primary' htmlType='submit'>
							Оплатить
						</Button>
					)}
				</div>
			</Form>
		</Modal>
	)
}

export { CloudModal }
